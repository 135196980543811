import { openPopup } from '../helpers/popup-helper';
import { readCookie } from './../helpers/cookies-helper';
const shareTriggerClass = 'js-share-page';
const popupId = 'js-share-page-popup';
const shareButtonClass = 'js-share-button';

document.addEventListener('click', function (e) {
    if (e.target.matches('.' + shareTriggerClass)) {
        e.preventDefault();

        const popup = document.getElementById(popupId);
        let target = e.target.href;
        target = target.replace(/\/#$/, '');

        let cookie = readCookie('lokum_fav');
        if (popup) {
            document.querySelector('.' + shareButtonClass).dataset.url =
                target + '?show=' + cookie;
            openPopup(popup);
        }
    }
});

const mailShareClass = 'js-share-in-email';
document.addEventListener('click', function (e) {
    if (e.target.matches('.' + mailShareClass)) {
        let url = e.target.dataset.url;
        let description = e.target.dataset.description;

        window.open('mailto:?subject=' + description + '&body=' + url);
    }
});

const copyShareClass = 'js-share-in-copy';
document.addEventListener('click', async function (e) {
    if (e.target.matches('.' + copyShareClass)) {
        let url = e.target.dataset.url;

        await copyToClipboard(url);
        e.target.classList.add('is-copied');
    }
});

async function copyToClipboard(textToCopy) {
    if (navigator.clipboard && window.isSecureContext) {
        await navigator.clipboard.writeText(textToCopy);
    } else {
        const textArea = document.createElement('textarea');
        textArea.value = textToCopy;

        textArea.style.position = 'absolute';
        textArea.style.left = '-999999px';

        document.body.prepend(textArea);
        textArea.select();

        try {
            document.execCommand('copy');
        } catch (error) {
            console.error(error);
        } finally {
            textArea.remove();
        }
    }
}
